
import Vue from "vue";

export default Vue.extend({
  name: "ForgotPasswordLogin",
  components: {},
  data() {
    return {
      data: {
        email: "",
        password: ""
      },
      passwordFieldType: "password",
      loginBtnLoading: false
    };
  },
  methods: {
    switchPasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    onLogin() {
      if (this.data.email === "" || this.data.password === "") return;
      this.loginBtnLoading = true;
      this.$store
        .dispatch("auth/LOGIN_USER", this.data)
        .then(() => {
          this.loginBtnLoading = false;
          // @ts-ignore
          this.$alertify.success(
            (this as any).$t("forgetPassword.forgetPassworLoginSuccess")
          );

          this.$router.push("/mentorcasts");
        })
        .catch(error => {
          this.loginBtnLoading = false;
          if (error.response.status === 422) {
            for (const e of error.response.data.errors) {
              // @ts-ignore
              this.$alertify.error(e);
            }
          }
        });
    }
  }
});
